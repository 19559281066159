import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "guest";

const getAllGuestsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_GUESTS,
});

const createGuestLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GUEST,
  // showErrorMessage: true,
  // errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  // showSuccessMessage: true,
  // successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch,data) => {
    dispatch(push(`/spinningWheel/displayed-wheel/${data?.spinningWheelId}`));
  },
});
const editGuestRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_GUEST_REWARD,
});
const getGuestsReportLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_GUESTS_REPORT,
});

export default [
  getAllGuestsLogic,
  createGuestLogic,
  editGuestRewardLogic,
  getGuestsReportLogic,
];
