import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllGuests: { args: ["payload"], meta: { async: true } },
    createGuest: { args: ["payload"], meta: { async: true } },
    editGuestReward: { args: ["payload"], meta: { async: true } },
    getGuestsReport: { args: ["payload"], meta: { async: true } },
    setGuest: { args: [], meta: { async: false } },
    setItem: { args: ["payload"], meta: { async: false } },

  },
  {
    prefix: "@app/guest/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
