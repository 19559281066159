import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("guests", { metadata: undefined }, []),
  ...defaultState("guest", { metadata: undefined }, {}),
};

const guestCreatedSuccess = (state, draft, payload) => {
  const guests = state.guests || [];
  draft.guests = [...guests, payload];
  draft.openDialog = false;
  localStorage.setItem("registeredGuest", JSON.stringify(payload));
};
const guestEditSuccess = (state, draft, payload) => {
  const guests = state.guests;
  var index = guests.findIndex((s) => s.id === payload.id);
  draft.guests[index] = payload;
  draft.openDialog = false;
  localStorage.removeItem("registeredGuest");
};

const setGuest = (state, draft, payload) => {
  draft.guest = payload;
};
const setItem = (state, draft, payload) => {
  const { item, data } = payload;
  draft[item] = data;
};


export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllGuests",
    stateName: "guests",
    async: true,
    isPaginated: true,
  }),

  ...bindReducers(ActionTypes, {
    action: "createGuest",
    stateName: "guest",
    async: true,
    isPaginated: false,
    successCb: guestCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editGuestReward",
    stateName: "guestReward",
    async: true,
    isPaginated: false,
    successCb: guestEditSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getGuestsReport",
    stateName: "guestsReport",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setGuest",
    stateName: "guestSet",
    async: false,
    isPaginated: false,
    successCb: setGuest,
  }),
  ...bindReducers(ActionTypes, {
    action: "setItem",
    stateName: "setItem",
    async: false,
    isPaginated: false,
    successCb: setItem,
  }),
});
