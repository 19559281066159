// import { createLogic } from "redux-logic";
import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "userManegment";
const getUsersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USER,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getPersonLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PERSON,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getVisitorLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_VISITOR,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getPermissionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PERMISSIONS,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const createUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_USER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createVisitorLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_VISITOR,
  showErrorMessage: true,
  successMessage: "Visitor created successfully",
  showSuccessMessage: true,
  successCb: (dispatch,res) => {
    dispatch(push(`/visitor/details/${res?.data?.id}`));
  },
});
const createPersonLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_PERSON,
  showErrorMessage: true,
  successMessage: "Person created successfully",
  showSuccessMessage: true,
  successCb: (dispatch,res) => {
    dispatch(push(`/person/details/${res?.data?.id}`));
  },
});
const createUsersLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_USERS,
  showErrorMessage: true,
  successMessage: "users created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createAppUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_APP_USER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const scanUserCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.SCAN_USER_CODE,
  // errorMessage: "there_is_an_error_occurred_try_again_later",

  showErrorMessage: true,
  // successMessage: "user Sign in Succefully",
  showSuccessMessage: true,
  
});
const signOutUserCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.SIGN_OUT_USER_CODE,
  // errorMessage: "there_is_an_error_occurred_try_again_later",

  showErrorMessage: true,
  // successMessage: "user Sign in Succefully",
  showSuccessMessage: true,
  
});
const createRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_ROLE,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/roles/list"));
  },
});
const getRolesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ROLES,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ROLE,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const deleteUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_USER,
  showErrorMessage: true,
  successMessage: "user Deleted successfully",
  showSuccessMessage: true,
});
const editUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_USER,
  showErrorMessage: true,
  showSuccessMessage: "User Edited Successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const editRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_ROLE,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/roles/list"));
  },
});
const deleteRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_ROLE,
  showErrorMessage: true,
  successMessage: "Role Deleted successfully",
  showSuccessMessage: true,
});

export default [
  getUsersLogic,
  createUserLogic,
  createUsersLogic,
  createAppUserLogic,
  getRolesLogic,
  getUserLogic,
  getPersonLogic,
  getVisitorLogic,
  deleteUserLogic,
  editUserLogic,
  createRoleLogic,
  getPermissionsLogic,
  deleteRoleLogic,
  editRoleLogic,
  getRoleLogic,
  scanUserCodeLogic,
  signOutUserCodeLogic,
  createVisitorLogic,
  createPersonLogic
];
