export default function (/**@type {ApisauceInstance} */ api) {
  const createConfig = (payload) =>
    api.post("/Integration/add-config", payload);
  const getAllConfigs = (paging) =>
    api.get("/Integration/configs-search", paging);
  const deleteConfig = (id) => api.delete(`/Integration/delete-config/${id}`);
  const editConfig = ({ id, payload }) =>
    api.put(`/Integration/edit-config`, payload);
  const getConfigWithId = (id) => api.get(`/Integration/find/${id}`);

  return {
    integration: {
      createConfig,
      getAllConfigs,
      deleteConfig,
      editConfig,
      getConfigWithId,
    },
  };
}
