import appLogic from "./app/logic";
import authLogic from "./auth/logic";
import spinningWheelLogic from "./spinningWheel/logic";
import tenantLogic from "./tenant/logic";
import usersLogic from "./userManagement/logic";
import guestLogic from "./registration/logic";
import integrationLogic from "./Integration/logic";

export default [
  ...appLogic,
  ...authLogic,
  ...spinningWheelLogic,
  ...tenantLogic,
  ...usersLogic,
  ...guestLogic,
  ...integrationLogic,
];
