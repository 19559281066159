export default function (/**@type {ApisauceInstance} */ api) {
  const getAllGuests = (paging) => api.get("/guest/guests", paging);
  const createGuest = (payload) => api.post("/guest/register-guest", payload);
  const editGuestReward = (payload) =>
    api.put(`guest/edit-guest-reward`, payload);
  const getGuestsReport = (payload) => api.get("guest/guests-report", payload);

  return {
    guest: {
      getAllGuests,
      createGuest,
      editGuestReward,
      getGuestsReport,
    },
  };
}
