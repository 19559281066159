export default function (/**@type {ApisauceInstance} */ api) {
  const getSpinningWheels = (payload) =>
    api.get("SpinningWheel/wheels", payload);
  const getSpinningWheelWithId = (id) => api.get(`SpinningWheel/wheel/${id}`);
  const getDisplayedSpinningWheel = (id) =>
    api.get(`SpinningWheel/displayed-wheel/${id}`);
  const deleteSpinningWheel = (id) =>
    api.delete(`SpinningWheel/delete-wheel/${id}`);
  const editSpinningWheel = ({ id, payload }) =>
    api.put(`SpinningWheel/edit-wheel`, payload);

  const createSpinningWheel = (payload) =>
    api.post("SpinningWheel/add-wheel", payload);

  ////rewards
  const getRewards = (payload) => api.get("reward/rewards", payload);
  const deleteReward = (id) => api.delete(`Reward/delete-reward/${id}`);
  const editReward = ({ id, payload }) =>
    api.put(`Reward/edit-reward`, payload);
  const reduceRewardQuantity = (payload) =>
    api.put(`Reward/reduce-reward-quantity`, payload);
  
    const spinFinished = (payload) =>
    api.post(`Reward/spin-finished`, payload);
  
    const createReward = (payload) => api.post("Reward/add-reward", payload);
  const getRewardsImages = (payload) =>
    api.get("reward/rewards-images", payload);
  const getRewardsReport = (payload) =>
    api.get("reward/rewards-report", payload);
  const resetRewardsQuantities = (payload) =>
    api.post("Reward/reset-spin-rewards-quantity", payload);
  ///extraData
  const getAllExtraData = (id) => api.get(`reward/extra-data/${id}`);
  const deleteExtraData = (id) => api.delete(`Reward/remove-extra-data/${id}`);
  const createExtraData = (payload) =>
    api.post("Reward/add-extra-data", payload);

  ////segments
  const getSegments = (payload) => api.get("segment/segments", payload);
  const createSegment = (payload) => api.post("segment/add-segment", payload);
  const editSegment = ({ id, payload }) =>
    api.put(`segment/edit-segment`, payload);
  const deleteSegment = (id) => api.delete(`segment/delete-segment/${id}`);

  ////////// Fields
  const getFields = (payload) =>
    api.get("SpinningWheelField/spin-fields", payload);
  const createField = (payload) =>
    api.post("SpinningWheelField/add-spin-field", payload);
  const deleteField = (id) =>
    api.delete(`SpinningWheelField/delete-field/${id}`);

  return {
    spinningWheels: {
      createSpinningWheel,
      getSpinningWheels,
      getDisplayedSpinningWheel,
      getSpinningWheelWithId,
      deleteSpinningWheel,
      editSpinningWheel,
      ////rewards
      getRewards,
      deleteReward,
      editReward,
      createReward,
      reduceRewardQuantity,
      spinFinished,
      getRewardsImages,
      getRewardsReport,
      resetRewardsQuantities,
      getAllExtraData,
      deleteExtraData,
      createExtraData,
      //segments
      deleteSegment,
      getSegments,
      createSegment,
      editSegment,
      //// Fields
      getFields,
      createField,
      deleteField,
    },
  };
}
