export default function (/**@type {ApisauceInstance} */ api) {
    const getAllTenants = (paging) => api.get("/tenant/search", paging);
    const getTenantWithId = (id) => api.get("/tenant/find/" + id);
  
    const createTenant = (payload) => api.post("/tenant/create", payload);
    const updateTenant = (payload) => api.put("/tenant/edit", payload);
    const deleteTenant = (id) => api.delete("/tenant/remove/" + id);
  
    return {
      tenant: {
        getAllTenants,
        getTenantWithId,
        createTenant,
        updateTenant,
        deleteTenant
      },
    };
  }
  