import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("integrations", { metadata: undefined }, []),
  ...defaultState("integration", { metadata: undefined }, {}),
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};
const setConfig = (state, draft, payload) => {
  const { config, data } = payload;
  draft[config] = data;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "createConfig",
    stateName: "config",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "getAllConfigs",
    stateName: "configs",
    async: true,
    isPaginated: true,
  }),

  ...bindReducers(ActionTypes, {
    action: "getConfigWithId",
    stateName: "config",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteConfig",
    stateName: "config",
    async: true,
    isPaginated: false,
    // successCb: userDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editConfig",
    stateName: "config",
    async: true,
    isPaginated: false,
    // successCb: userDeleteSuccess,
  }),
  ///////
  ...bindReducers(ActionTypes, {
    action: "setConfig",
    stateName: "setConfig",
    async: false,
    isPaginated: false,
    successCb: setConfig,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
