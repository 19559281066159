import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    createSpinningWheel: { args: ["payload"], meta: { async: true } },
    getSpinningWheelWithId: { args: ["payload"], meta: { async: true } },
    getDisplayedSpinningWheel: { args: ["payload"], meta: { async: true } },
    getSpinningWheels: { args: ["payload"], meta: { async: true } },
    editSpinningWheel: { args: ["payload"], meta: { async: true } },
    deleteSpinningWheel: { args: ["payload"], meta: { async: true } },

    //rewards
    createReward: { args: ["payload"], meta: { async: true } },
    getRewards: { args: ["payload"], meta: { async: true } },
    editReward: { args: ["payload"], meta: { async: true } },
    deleteReward: { args: ["payload"], meta: { async: true } },
    reduceRewardQuantity: { args: ["payload"], meta: { async: true } },
    spinFinished: { args: ["payload"], meta: { async: true } },
    getRewardsImages: { args: ["payload"], meta: { async: true } },
    getRewardsReport: { args: ["payload"], meta: { async: true } },
    resetRewardsQuantities: { args: ["payload"], meta: { async: true } },
    //extraData
    getAllExtraData: { args: ["payload"], meta: { async: true } },
    deleteExtraData: { args: ["payload"], meta: { async: true } },
    createExtraData: { args: ["payload"], meta: { async: true } },

    //segments
    getSegments: { args: ["payload"], meta: { async: true } },
    createSegment: { args: ["payload"], meta: { async: true } },
    editSegment: { args: ["payload"], meta: { async: true } },
    deleteSegment: { args: ["payload"], meta: { async: true } },
    ////// Fields
    getFields: { args: ["payload"], meta: { async: true } },
    createField: { args: ["payload"], meta: { async: true } },
    deleteField: { args: ["payload"], meta: { async: true } },

    setSpinningWheel: { args: [], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    showDeleteSegmentDialog: { args: [], meta: { async: false } },
    showDeleteFieldDialog: { args: [], meta: { async: false } },
    showDynamicDialog: { args: ["payload"], meta: { async: false } },
    resetDynamicDialog: { args: ["payload"], meta: { async: false } },
    setItem: { args: ["payload"], meta: { async: false } },
  },
  {
    prefix: "@app/spinningWheels/",
  }
);
export const ActionTypes = Types;
export default Creators;
