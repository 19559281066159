import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    createConfig: { args: ["payload"], meta: { async: true } },

    getAllConfigs: { args: ["payload"], meta: { async: true } },

    getConfigWithId: { args: ["payload"], meta: { async: true } },
    deleteConfig: { args: ["payload"], meta: { async: true } },
    editConfig: { args: ["payload"], meta: { async: true } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    setConfig: { args: ["payload"], meta: { async: false } },
  },
  {
    prefix: "@app/integration/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
