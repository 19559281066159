import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "spinningWheels",
    {
      metadata: undefined,
    },
    []
  ),
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.openDeleteSegmentDialog = false;
  draft.openDeleteFieldDialog = false;
};

const showDeleteSegmentDialog = (state, draft, payload) => {
  draft.openDeleteSegmentDialog = true;
};
const showDeleteFieldDialog = (state, draft, payload) => {
  draft.openDeleteFieldDialog = true;
};
const setSpinningWheel = (state, draft, payload) => {
  draft.spinningWheel = payload;
};

const spinningWheelDeleteSuccess = (state, draft, payload) => {
  const spinningWheels = state.spinningWheels;
  draft.spinningWheels = spinningWheels.filter((s) => s.id != payload);
  draft.openDeleteDialog = false;
};

const spinningWheelEditedSuccess = (state, draft, payload) => {
  const spinningWheels = state.spinningWheels;
  var index = spinningWheels.findIndex((s) => s.id === payload.id);
  draft.spinningWheels[index] = payload;
};

const rewardDeleteSuccess = (state, draft, payload) => {
  const rewards = state.rewards;
  draft.rewards = rewards.filter((s) => s.id != payload);
  draft.openDeleteDialog = false;
};

const rewardCreateSuccess = (state, draft, payload) => {
  const rewards = state.rewards;
  draft.rewards = [...rewards, payload];
  draft.openDialog = false;
};
const rewardEditSuccess = (state, draft, payload) => {
  const rewards = state.rewards;
  var index = rewards.findIndex((s) => s.id === payload.id);
  draft.rewards[index] = payload;
  draft.openDialog = false;
};
const reduceRewardQuantitySuccess = (state, draft, payload) => {
  draft.spinningWheel = payload;
};
const ResetRewardsQuantitiesSuccess = (state, draft, payload) => {
  var rewards = state.rewards.forEach((reward) => ({ ...reward, quantity: 0 }));
  draft.rewards = [...rewards];
};
///extra data
const extraDeletedSuccess = (state, draft, payload) => {
  const extraList = state.extraList;
  draft.extraList = extraList.filter((s) => s.id != payload);
  draft.openDeleteDialog = false;
};
const extraCreateSuccess = (state, draft, payload) => {
  const extraList = state.extraList;
  draft.extraList = [...extraList, payload];
  draft.openDialog = false;
};
//segments
const segmentCreateSuccess = (state, draft, payload) => {
  const segments = state.segments;
  draft.segments = [...segments, payload];
  draft.openDialog = false;
};
const segmentEditSuccess = (state, draft, payload) => {
  const segments = state.segments;
  var index = segments.findIndex((s) => s.id === payload.id);
  draft.segments[index] = payload;
  draft.openDialog = false;
};
const segmentDeleteSuccess = (state, draft, payload) => {
  const segments = state.segments;
  draft.segments = segments.filter((s) => s.id != payload);
  draft.openDeleteSegmentDialog = false;
};
///field
const fieldCreateSuccess = (state, draft, payload) => {
  const fields = state.fields;
  draft.fields = [...fields, payload];
  draft.openDialog = false;
};
const fieldDeleteSuccess = (state, draft, payload) => {
  const fields = state.fields;
  draft.fields = fields.filter((s) => s.id != payload);
  draft.openDeleteSegmentDialog = false;
};

const spinFinishedSuccess =(state, draft, payload) => {
console.log("spinFinishedSuccess",payload)
//window.ReactNativeWebView.postMessage(JSON.stringify(payload))
}
const setItem = (state, draft, payload) => {
  const { item, data } = payload;
  draft[item] = data;
};

const showDynamicDialog = (state, draft, payload) => {
  const { item } = payload;
  draft[item] = true;
};
const resetDynamicDialog = (state, draft, payload) => {
  const { item } = payload;
  draft[item] = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "createSpinningWheel",
    stateName: "spinningWheel",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "getSpinningWheelWithId",
    stateName: "spinningWheel",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getDisplayedSpinningWheel",
    stateName: "spinningWheel",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getSpinningWheels",
    stateName: "spinningWheels",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "editSpinningWheel",
    stateName: "spinningWheel",
    async: true,
    isPaginated: false,
    successCb: spinningWheelEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteSpinningWheel",
    stateName: "spinningWheelDeleted",
    async: true,
    isPaginated: false,
    successCb: spinningWheelDeleteSuccess,
  }),
  ///////Rewards
  ...bindReducers(ActionTypes, {
    action: "getRewards",
    stateName: "rewards",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRewardsImages",
    stateName: "rewardsImages",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRewardsReport",
    stateName: "rewardsReport",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteReward",
    stateName: "reward",
    async: true,
    isPaginated: false,
    successCb: rewardDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "createReward",
    stateName: "reward",
    async: true,
    isPaginated: false,
    successCb: rewardCreateSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "editReward",
    stateName: "reward",
    async: true,
    isPaginated: false,
    successCb: rewardEditSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "reduceRewardQuantity",
    stateName: "rewards",
    async: true,
    isPaginated: false,
    successCb: reduceRewardQuantitySuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "spinFinished",
    stateName: "spinFinishedRes",
    async: true,
    isPaginated: false,
   successCb: spinFinishedSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteSegmentDialog",
    stateName: "showDeleteSegmentDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteSegmentDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteFieldDialog",
    stateName: "showDeleteFieldDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteFieldDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "setSpinningWheel",
    stateName: "spinningWheel",
    async: false,
    isPaginated: false,
    successCb: setSpinningWheel,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetRewardsQuantities",
    stateName: "rewards",
    async: true,
    isPaginated: false,
    // successCb: ResetRewardsQuantitiesSuccess,
  }),
  //extraData
  ...bindReducers(ActionTypes, {
    action: "createExtraData",
    stateName: "extraData",
    async: true,
    isPaginated: false,
    successCb: extraCreateSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllExtraData",
    stateName: "extraList",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteExtraData",
    stateName: "extraData",
    async: true,
    isPaginated: false,
    successCb: extraDeletedSuccess,
  }),
  //segments
  ...bindReducers(ActionTypes, {
    action: "getSegments",
    stateName: "segments",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createSegment",
    stateName: "segment",
    async: true,
    isPaginated: false,
    successCb: segmentCreateSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editSegment",
    stateName: "segment",
    async: true,
    isPaginated: false,
    successCb: segmentEditSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteSegment",
    stateName: "segment",
    async: true,
    isPaginated: false,
    successCb: segmentDeleteSuccess,
  }),

  ////field
  ...bindReducers(ActionTypes, {
    action: "getFields",
    stateName: "fields",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createField",
    stateName: "field",
    async: true,
    isPaginated: false,
    successCb: fieldCreateSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteField",
    stateName: "field",
    async: true,
    isPaginated: false,
    successCb: fieldDeleteSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "setItem",
    stateName: "setItem",
    async: false,
    isPaginated: false,
    successCb: setItem,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDynamicDialog",
    stateName: "dialog",
    async: false,
    isPaginated: false,
    successCb: showDynamicDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDynamicDialog",
    stateName: "dialog",
    async: false,
    isPaginated: false,
    successCb: resetDynamicDialog,
  }),
});
