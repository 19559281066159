import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";
import spinActions from "src/store/spinningWheel/actions";

const apiNamespace = "spinningWheels";

const getSpinningWheelWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SPINNING_WHEEL_WITH_ID,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getDisplayedSpinningWheelLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_DISPLAYED_SPINNING_WHEEL,
  showErrorMessage: true,
  showSuccessMessage: false,

  // failCb: (dispatch) => {
  //     dispatch(push('/spinning-wheel/list'))
  // }
});
const getSpinningWheelsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SPINNING_WHEELS,
});
const editSpinningWheelLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_SPINNING_WHEEL,
  // successCb: (dispatch) => {
  //     dispatch(push("/spinningWheel/list"))
  // }

  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteSpinningWheelLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SPINNING_WHEEL,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_deleted_successfully" />,
});

const createSpinningWheelLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SPINNING_WHEEL,

  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/spinningWheel/list"));
  },
});
//rewards
const getRewardsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REWARDS,
});

const getRewardsImagesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REWARDS_IMAGES,
});
const getRewardsReportLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REWARDS_REPORT,
});
const createRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_REWARD,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_added_successfully" />,
});
const editRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_REWARD,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteRewardLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_REWARD,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_deleted_successfully" />,
});
const reduceRewardQuantityLogic = logic(apiNamespace, {
  actionName: ActionTypes.REDUCE_REWARD_QUANTITY,
  //  successCb: (dispatch,data) => {
  //  if(data?.spinningWheelId) dispatch(push(`/spinningWheel/displayed-wheel/${data?.spinningWheelId}`));
  // },
});
const spinFinishedLogic = logic(apiNamespace, {
  actionName: ActionTypes.SPIN_FINISHED,

  successCb: (dispatch, data) => {
    dispatch(spinActions?.getSpinningWheelWithId(data?.spinningWheelId))
    // if(data?.spinningWheelId) dispatch(push(`/spinningWheel/displayed-wheel/${data?.spinningWheelId}`));
  },
});
const resetRewardsQuantitiesLogic = logic(apiNamespace, {
  actionName: ActionTypes.RESET_REWARDS_QUANTITIES,
});
///extra Data
const getAllExtraDataLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_EXTRA_DATA,
});
const deleteExtraDataLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_EXTRA_DATA,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_deleted_successfully" />,
});
const createExtraDataLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_EXTRA_DATA,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_added_successfully" />,
});
//Segments

const getSegmentsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SEGMENTS,
});

const createSegmentLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SEGMENT,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_added_successfully" />,
});

const editSegmentLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_SEGMENT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteSegmentLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SEGMENT,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_deleted_successfully" />,
});
//// Fields

const getFieldsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_FIELDS,
});

const createFieldLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_FIELD,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_added_successfully" />,
});

const deleteFieldLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_FIELD,
  showErrorMessage: true,

  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

  showSuccessMessage: true,

  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  createSpinningWheelLogic,
  getSpinningWheelWithIdLogic,
  getSpinningWheelsLogic,
  getDisplayedSpinningWheelLogic,
  editSpinningWheelLogic,
  deleteSpinningWheelLogic,
  getRewardsLogic,
  createRewardLogic,
  editRewardLogic,
  deleteRewardLogic,
  reduceRewardQuantityLogic,
  spinFinishedLogic,
  getRewardsImagesLogic,
  getRewardsReportLogic,
  resetRewardsQuantitiesLogic,
  getAllExtraDataLogic,
  createExtraDataLogic,
  deleteExtraDataLogic,
  //Segments
  getSegmentsLogic,
  createSegmentLogic,
  editSegmentLogic,
  deleteSegmentLogic,
  ////Fields
  createFieldLogic,
  deleteFieldLogic,
  getFieldsLogic,
];
