import { ActionTypes } from "./actions";
import logic from "src/utils/genLogic";
import { push } from "connected-react-router";
import jwtDecoder from "jwt-decode";

const apiNamespace = "auth";

const loginLogic = logic(apiNamespace, {
  actionName: ActionTypes.LOGIN,
  showErrorMessage: true,
  errorMessage: "Invalid login ",
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/home"));
  },
});

const refreshTokenLogic = logic(apiNamespace, {
  actionName: ActionTypes.REFRESH_TOKEN,
});

export const loginExternalLogic = logic(apiNamespace, {
  actionName: ActionTypes.LOGIN_EXTERNAL,
  showErrorMessage: true,
  errorMessage: "Invalid login ",
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/home"));
  },
});

const changePasswordLogic = logic(apiNamespace, {
  actionName: ActionTypes.CHANGE_PASSWORD,
  showErrorMessage: true,
  errorMessage: "Faild to change your password ",
  showSuccessMessage: true,
  successMessage: "Your password updated successfully",
});
const updateProfileLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_PROFILE,
  showErrorMessage: true,
  errorMessage: "Faild to update your profile",
  showSuccessMessage: true,
  successMessage: "Your profile updated successfully",
});

export default [
  changePasswordLogic,
  updateProfileLogic,
  loginLogic,
  loginExternalLogic,
  refreshTokenLogic,
];
