import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "integration";

const createConfigLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CONFIG,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/configs/list"));
  },
});

const getAllConfigsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CONFIGS,
});

const getConfigWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CONFIG_WITH_ID,
});

const deleteConfigLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CONFIG,
  showErrorMessage: true,
  successMessage: "config Deleted successfully",
  showSuccessMessage: true,
});
const editConfigLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CONFIG,
  showErrorMessage: true,
  showSuccessMessage: "config Edited Successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/configs/list"));
  },
});

export default [
  createConfigLogic,
  getAllConfigsLogic,
  getConfigWithIdLogic,
  editConfigLogic,
  deleteConfigLogic,
];
