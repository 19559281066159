export default function (/**@type {ApisauceInstance} */ api) {
  const getUsers = (paging) => api.get("user-management/users", paging);
  const getPermissions = () => api.get("user-management/permissions");
  const createUser = (payload) => api.post("user-management/users", payload);
  const createUsers = (payload) =>
    api.post("user-management/add-users", payload);
  const createAppUser = (payload) =>
    api.post("user-management/users/app-user", payload);
  const getUser = (id) => api.get(`user-management/users/${id}`);
  const getPerson = (id) => api.get(`Add/get-person/${id}`);
  const getVisitor = (id) => api.get(`Add/get-visitor/${id}`);
  const getRoles = (paging) => api.get("user-management/roles", paging);
  const deleteUser = (id) =>
    api.delete(`user-management/users/delete-user/${id}`);
  const createRole = (payload) =>
    api.post("user-management/roles/add-role", payload);
  const editUser = ({ payload, id }) =>
    api.put(`user-management/users/edit-user/${id}`, payload);
  const deleteRole = (id) =>
    api.delete(`user-management/roles/delete-role/${id}`);
  const editRole = ({ payload, id }) =>
    api.put(`user-management/roles/edit-role/${id}`, payload);
  const getRole = (id) => api.get(`user-management/roles/${id}`);

  return {
    userManegment: {
      getUsers,
      getUser,
      createUser,
      createUsers,
      createAppUser,
      deleteUser,
      getRoles,
      editUser,
      createRole,
      getPermissions,
      deleteRole,
      editRole,
      getRole,
    },
  };
}
