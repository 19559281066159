import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("tenants", { metadata: undefined }, []),
  ...defaultState("tenant", { metadata: undefined }, {}),
};

const tenantEditedSuccess = (state, draft, payload) => {
  const tenants = state.tenants || [];
  var tenantIndex = tenants.findIndex((u) => u.id === payload.id);
  draft.tenants[tenantIndex] = payload;
  draft.openDialog = false;
};

const tenantCreatedSuccess = (state, draft, payload) => {
  const tenants = state.tenants || [];
  draft.tenants = [...tenants, payload];
  draft.openDialog = false;
};

const tenantDeleteSuccess = (state, draft, payload) => {
  const tenants = state.tenants || [];
  draft.tenants = tenants.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const setTenant = (state, draft, payload) => {
  draft.tenant = undefined;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.tenant_errors = [];
};

export const reducer = createReducer(initialState, {

  ...bindReducers(ActionTypes, {
    action: "getAllTenants",
    stateName: "tenants",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTenantWithId",
    stateName: "tenant",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createTenant",
    stateName: "tenant",
    async: true,
    isPaginated: false,
    successCb: tenantCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateTenant",
    stateName: "tenant",
    async: true,
    isPaginated: false,
    successCb: tenantEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteTenant",
    stateName: "tenant",
    async: true,
    isPaginated: false,
    successCb: tenantDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setTenant",
    stateName: "tenant",
    async: false,
    isPaginated: false,
    successCb: setTenant,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
