import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "tenant";

const getAllTenantsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_TENANTS,
});

const getTenantWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TENANT_WITH_ID,
});
const createTenantLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_TENANT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/tenant/list"));
  },
});

const updateTenantLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_TENANT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/tenant/list"));
  },
});

const deleteTenantLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_TENANT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllTenantsLogic,
  getTenantWithIdLogic,
  createTenantLogic,
  updateTenantLogic,
  deleteTenantLogic
];
