import { createReducer } from "reduxsauce";
import { produce } from "immer";
import jwtDecoder from "jwt-decode";

import { ActionTypes } from "./actions";
import { bindReducers } from "src/utils/genState";

const initialState = {
  user: null,
  isLoggedIn: false,
  token: null,
  loginError: undefined,
  notifications: [],
};

const login = (state) => {
  return produce(state, (draft) => {
    draft.loginLoading = true;
  });
};
const loginSuccess = (state, draft, payload) => {
  //console.log("payload",payload)
  const user = jwtDecoder(payload.token);
  draft.user = user;
  draft.isLoggedIn = true;
  draft.loginLoading = false;
  const roles = JSON.parse(user?.role);
  localStorage.setItem("userData", JSON.stringify(user));
  localStorage.setItem("jwtToken", payload.token);
  localStorage.setItem("refreshToken", payload.refreshToken);
  draft.login = undefined;
};

const loginFail = (state, { payload }) => {
  return produce(state, (draft) => {
    draft.loginLoading = false;
    draft.loginError = payload.message;
  });
};
const loginExternal = (state) => {
  return produce(state, (draft) => {
    draft.loginExternalLoading = true;
  });
};
const loginExternalSuccess = (state, draft, payload) => {
  const user = jwtDecoder(payload.token);
  draft.user = user;
  draft.isLoggedIn = true;
  draft.loginLoading = false;
  localStorage.setItem("userData", JSON.stringify(user));
  localStorage.setItem("jwtToken", payload.token);
  draft.login = undefined;
};

const loginExternalFail = (state, draft, payload) => {
  draft.loginExternalLoading = false;
  draft.loginExternalLoadingError = payload.errorMessage;
};
const updateTokenSuccess = (state, draft, payload) => {
  const user = jwtDecoder(payload);
  if (user.active === false) {
    localStorage.removeItem("userData");
    localStorage.removeItem("jwtToken");
    draft.user = undefined;
    draft.isLoggedIn = false;
    return;
  }
  draft.user = user;
  draft.loginLoading = false;
  localStorage.setItem("userData", JSON.stringify(user));
  localStorage.setItem("jwtToken", payload);
};
const updateUserScoreSuccess = (state, draft, payload) => {
  draft.user = { ...draft.user, score: payload };
};
const refreshTokenSuccess = (state, draft, payload) => {
  localStorage.setItem("jwtToken", payload.token);
  localStorage.setItem("refreshToken", payload.refreshToken);
  draft.refreshToken = undefined;
}
const refreshTokenFailure = (state, draft, payload) => {
  localStorage.removeItem("userData");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("refreshToken");

  draft.user = undefined;
  draft.isLoggedIn = false;
}
const switchAsTenantSuccess = (state, draft, payload) => {
  if (!payload) return;
  const { tenantId, switched } = payload;
  if (!switched) {
    localStorage.removeItem("t__id");
    draft.user = { ...state.user, switched: false }
    return;
  }
  localStorage.setItem("t__id", tenantId);
  draft.user = { ...state.user, switched: true }
}
const switchAsTenantFailure = (state, draft, payload) => {
}

const logout = (state, { payload }) => {
  return produce(state, (draft) => {
    localStorage.removeItem("userData");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    draft.user = undefined;
    draft.refreshToken = undefined;
    draft.isLoggedIn = false;
  });
};
const resetPasswordSuccess = (state, draft, payload) => {
  draft.changePassword_errors = null;
};

export const authReducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "login",
    stateName: "login",
    async: true,
    isPaginated: false,
    successCb: loginSuccess,
  }),

  // ...bindReducers(ActionTypes, {
  //   action: "refreshToken",
  //   stateName: "refreshToken",
  //   async: true,
  //   isPaginated: false,
  //   successCb: loginSuccess
  // }),

  ...bindReducers(ActionTypes, {
    action: "loginExternal",
    stateName: "loginExternal",
    async: true,
    isPaginated: false,
    successCb: loginExternalSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateToken",
    stateName: "updateToken",
    async: false,
    isPaginated: false,
    successCb: updateTokenSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateProfile",
    stateName: "updateProfile",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "changePassword",
    stateName: "changePassword",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetChangePassword",
    stateName: "resetChangePassword",
    successCb: resetPasswordSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateUserScore",
    async: false,
    isPaginated: false,
    successCb: updateUserScoreSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "refreshToken",
    stateName: "refreshToken",
    async: true,
    isPaginated: false,
    successCb: refreshTokenSuccess,
    failCb: refreshTokenFailure
  }),
  ...bindReducers(ActionTypes, {
    action: "switchAsTenant",
    stateName: "switchAsTenant",
    async: false,
    isPaginated: false,
    successCb: switchAsTenantSuccess,
    failCb: switchAsTenantFailure
  }),
  [ActionTypes.LOGOUT]: logout,
});
